.header {
	padding: 1rem;
	background-color: #323c76;
	color: #FFF;
	display: flex;
	line-height: 34px;
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 99
}
