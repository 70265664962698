.container {
	padding: 0 1rem;
}
.searchField {
	padding: 1rem 0;
	display: flex;
	flex-direction: row-reverse;
}
.formControl {
	width: 170px;
}
td {
	svg {
		cursor: pointer;
	}
}