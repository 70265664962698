.header {
	padding: 1rem;
	background-color: #3f51b5;
	line-height: 34px;
	color: #FFF;
}
.container {
	min-height: 80vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.formContainer {
	width: 300px;
}
.error {
	font-size: 0.8rem;
	color: #bb0000;
}

.loginForm {
	display: flex;
	flex-direction: column;
	width: 300px;
}

.loginFormItem {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}
